import Model, { attr } from '@ember-data/model';

export default class Plan extends Model {
  @attr('string', { readOnly: true }) name;
  @attr('number', { readOnly: true }) price;
  @attr('number', { readOnly: true }) keywordLimit;
  @attr('number', { readOnly: true }) urlLimit;
  @attr('string', { readOnly: true }) accuracy;
  @attr('boolean', { readOnly: true }) backlinksEnabled;
  @attr('number', { readOnly: true }) backlinksLimit;
  @attr('number', { readOnly: true }) competitorsLimitPerUrl;
  @attr('boolean', { readOnly: true }) youtube;
  @attr('number', { readOnly: true }) period;
  @attr('string', { readOnly: true }) displayName;
  @attr('string', { readOnly: true }) periodName;
  @attr('string', { readOnly: true }) planCategoryName;
  @attr('boolean', { readOnly: true }) apiAccess;
  @attr('boolean', { readOnly: true }) places;
  @attr('boolean', { readOnly: true }) agency;
  @attr('string', { readOnly: true }) llmKeywordLimit;

  get features() {
    const featuresArray = [];
    if (this.apiAccess) {
      featuresArray.push('API');
    }
    if (this.agency) {
      featuresArray.push('Subusers');
      featuresArray.push('White-Label');
    }
    return featuresArray.join(', ');
  }

  get monthlyPrice() {
    switch (this.periodName) {
      case 'monthly':
        return this.price;
      case 'yearly':
        return this.price / 12;
      default:
        return null;
    }
  }
}
