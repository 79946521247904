import Component from '@glimmer/component';
import siteData from '../../constants/site-data';

const engineIcon = {
  google: '/assets/icons/gray/google.svg',
  youtube: '/assets/icons/gray/youtube.svg',
  places: '/assets/icons/gray/googlemaps.svg',
  bing: '/assets/icons/gray/bing.svg',
  yahoo: '/assets/icons/gray/yahoo.svg',
  duckduckgo: '/assets/icons/gray/duckduckgo.svg',
};

const deviceIcon = {
  mobile: '/assets/icons/gray/mobile.svg',
  desktop: '/assets/icons/gray/desktop.svg',
};

export default class NwOnboardingFlowPreviewTableComponent extends Component {
  get location() {
    if (this.args.keywordPreciseLocation) {
      return this.args.keywordPreciseLocation.description;
    }

    const code = this.args.url?.country_code?.toUpperCase();
    const country = [...siteData.countriesExtended].find((c) => c.id === code);

    return country?.label || '';
  }

  get keywords() {
    if (!this.args.provider?.keywords?.length) {
      return [...Array(10).keys()].map(() => {
        return {
          query: this.randString(this.randInt(8, 10)),
          engineImgPath: '/assets/icons/google-icon.svg',
          google_gl: this.args.url.country_code,
        };
      });
    }

    return this.args.provider.keywords;
  }

  get icon() {
    [].forEach.call(document.querySelectorAll('.engine-icon'), (el) => {
      el.classList.remove('fadeIn');
      setTimeout(() => el.classList.add('fadeIn'));
    });

    return engineIcon[this.args.url.engine];
  }

  get deviceIcon() {
    [].forEach.call(document.querySelectorAll('.device-icon'), (el) => {
      el.classList.remove('fadeIn');
      setTimeout(() => el.classList.add('fadeIn'));
    });

    return deviceIcon[this.args.url.device];
  }

  get lang() {
    [].forEach.call(document.querySelectorAll('.lang'), (el) => {
      el.classList.remove('fadeIn');
      setTimeout(() => el.classList.add('fadeIn'));
    });

    return this.args.url.language_code;
  }

  randString(len) {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz ';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < len) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  randInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
