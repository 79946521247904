import Component from '@glimmer/component';
import { isBlank } from '@ember/utils';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { PROPERTIES as SITE_AUDIT_COLUMNS } from 'nightwatch-web/utils/site-audit-properties';

export default class ReportBlockComponent extends Component {
  @service store;
  @tracked defaultDynamicView = { name: 'All Keywords', id: null };
  @tracked defaultBacklinkView = { name: 'All Backlinks', id: null };
  @tracked siteAuditIncludeChecks = false;
  @tracked siteAuditIncludeAllPages = false;
  @tracked selectedDynamicView;
  @tracked selectedSiteAuditCheckIds = [];
  @tracked selectedSiteAuditColumns = [];
  @tracked selectedSiteAuditSortColumnName = null;
  @tracked selectedSiteAuditSortDirection = null;
  @tracked siteAuditSnapshots = [];
  @tracked siteAuditChecks = [];
  @tracked allKeywordsCount;
  @tracked selectedSiteAuditView;
  @tracked selectedGraph;
  @tracked allBacklinksCount;
  @tracked selectedBacklinkView;
  @tracked selectedSiteAuditSnapshotId;

  get siteAuditChecksProblematic() {
    return this.siteAuditChecks.filter(
      (check) => check.category === 'problematic'
    );
  }

  get siteAuditChecksWarning() {
    return this.siteAuditChecks.filter((check) => check.category === 'warning');
  }

  get isGlobalViewReport() {
    return isBlank(this.args.url);
  }

  get keywordCount() {
    let count;
    if (this.selectedDynamicView?.id) {
      count = this.selectedDynamicView.keyword_count;
    } else {
      count = this.allKeywordsCount;
    }
    return count;
  }

  get graphsWithAllCompetitorsGraph() {
    const url = this.args.url;
    if (!url) {
      return [];
    }

    const graphs = (this.args.graphs || []).toArray();

    const alreadyContainsCompetitorGraphs = graphs.some((g) =>
      g.name.toLowerCase().includes('competitor')
    );
    if (alreadyContainsCompetitorGraphs) {
      return graphs;
    } else {
      return [
        {
          name: 'All Competitors',
          id: 'all_competitors',
          url_id: url.id,
        },
        ...graphs,
      ];
    }
  }

  get siteAuditViewsWithAll() {
    const siteAuditViews = (this.args.siteAuditViews || []).toArray();
    return [
      {
        name: 'All Pages',
        id: null,
      },
      ...siteAuditViews,
    ];
  }

  get siteAuditColumns() {
    return [{ name: 'none', displayName: 'None' }, ...SITE_AUDIT_COLUMNS];
  }

  get dynamicViewsWithAll() {
    const dynamicViews = (this.args.url?.dynamicViews || []).toArray();
    if (this.isGlobalViewReport) {
      return dynamicViews;
    }

    return [
      {
        name: 'All Keywords',
        id: null,
      },
      ...dynamicViews,
    ];
  }

  get backlinkViewsWithAll() {
    const backlinkViews = (this.args.backlinkViews || []).toArray();
    return [
      {
        name: 'All Backlinks',
        id: null,
      },
      ...backlinkViews,
    ];
  }

  // TODO
  // keywordCountObserver: observer('keywordCount', function () {
  //   // To get sum of keyword counts in report form
  //   scheduleOnce('afterRender', this, this.updateKeywordCount);
  // }),

  updateKeywordCount() {
    this.args.blockType._keywordCount = this.keywordCount;
  }

  get backlinkCount() {
    if (this.selectedBacklinkView?.id) {
      return this.selectedBacklinkView?.backlinkCount;
    } else {
      return this.allBacklinksCount;
    }
  }

  get pagesCount() {
    if (this.selectedSiteAuditView?.id) {
      return this.selectedSiteAuditView?.pageCount;
    } else {
      return this.args.url?.lastCrawlingSession?.pageCount;
    }
  }

  @action
  onUpdate() {
    const url = this.url;
    if (url) {
      this.allKeywordsCount = url.keyword_count;
      this.allBacklinksCount = url.backlinksCount;
    }
  }

  @task({ drop: true })
  *loadSiteAuditSnapshots() {
    try {
      const siteAuditSnapshots = yield this.store.query(
        'site-audit/crawling-session',
        {
          search_keyword_url_id: this.args.url.id,
          sort: 'started_at',
          direction: 'desc',
        }
      );
      this.siteAuditSnapshots = siteAuditSnapshots
        .toArray()
        .removeObject(this.args.url.lastCrawlingSession);
    } catch (error) {
      throw `Failed to load site audit crawling sessions: ${error}`;
    }
  }

  @task({ drop: true })
  *loadSiteAuditChecks() {
    try {
      const siteAuditChecks = yield this.store.query('site-audit/audit-check', {
        search_keyword_url_id: this.args.url.id,
      });
      this.siteAuditChecks = siteAuditChecks.toArray();
      if (this.selectedSiteAuditCheckIds.length === 0) {
        this.selectSiteAuditChecks();
      }
    } catch (error) {
      throw `Failed to load site audit checks: ${error}`;
    }
  }

  @action
  onInsert() {
    this.ensureSelectedDynamicView();
    this.ensureSelectedBacklinkView();
    this.ensureSelectedGraph();
    this.ensureSelectedSiteAudit();
  }

  ensureSelectedGraph() {
    const graphId = this.args.blockType.graph_id;

    this.selectedGraph = this.graphsWithAllCompetitorsGraph.findBy(
      'id',
      graphId
    );
  }

  ensureSelectedDynamicView() {
    const blockName = this.args.blockType?.name;
    const dynamicViewId = this.args.blockType?.view_id;

    if (
      !['keyword_overview', 'keyword_list', 'compare_periods'].includes(
        blockName
      )
    ) {
      return;
    }

    if (isBlank(dynamicViewId)) {
      this.selectedDynamicView = this.defaultDynamicView;
      return;
    }

    const promise = this.store.findRecord('dynamic-view', dynamicViewId);
    promise.then(
      (dynamicView) => {
        this.selectedDynamicView = dynamicView;
      },
      () => {
        this.selectedDynamicView = this.defaultDynamicView;
      }
    );
  }

  ensureSelectedBacklinkView() {
    const blockName = this.args.blockType?.name;
    if (!['backlink_overview'].includes(blockName)) {
      return;
    }

    const backlinkViewId = this.args.blockType?.view_id;
    if (isBlank(backlinkViewId)) {
      this.selectedBacklinkView = this.defaultBacklinkView;
      return;
    }

    const cachedBacklinkView = this.store.peekRecord(
      'backlink-view',
      backlinkViewId
    );
    if (cachedBacklinkView) {
      this.selectedBacklinkView = cachedBacklinkView;
      cachedBacklinkView.loadCount();
      return;
    }

    const promise = this.store.findRecord('backlink-view', backlinkViewId);
    promise.then(
      (backlinkView) => {
        this.selectedBacklinkView = backlinkView;
      },
      () => {
        this.selectedBacklinkView = this.defaultBacklinkView;
      }
    );
  }

  ensureSelectedSiteAudit() {
    const blockName = this.args.blockType.name;
    if (!['site_audit_overview', 'site_audit_pages'].includes(blockName)) {
      return;
    }

    const viewId = this.args.blockType.view_id ?? null;
    this.selectedSiteAuditView = this.siteAuditViewsWithAll.findBy(
      'id',
      viewId
    );

    this.selectedSiteAuditSnapshotId =
      this.args.blockType?.comparison_snapshot ?? null;
    const urlId = this.args.blockType?.url_id ?? this.args.url?.id;

    // this.selectedSiteAuditView = null;
    this.selectedSiteAuditSnapshotId = null;
    this.args.blockType.url_id = urlId;

    // TODO
    this.loadSiteAuditSnapshots.perform();

    if (blockName === 'site_audit_pages') {
      const includeChecks =
        this.args.blockType.include_site_audit_checks ?? true;
      const checkIds = this.args.blockType.site_audit_check_ids ?? [];

      const includeAllPages = this.args.blockType.include_all_pages ?? false;

      const selectedColumnNames = this.args.blockType.columns ?? [
        null,
        null,
        null,
        null,
      ];

      const columnName = this.args.blockType.sort ?? null;
      const direction = this.args.blockType.sort_direction ?? null;

      this.siteAuditIncludeChecks = includeChecks;
      this.selectedSiteAuditCheckIds = checkIds;
      this.siteAuditIncludeAllPages = includeAllPages;
      this.args.blockType.columns = selectedColumnNames;
      this.selectedSiteAuditColumns = selectedColumnNames.map(
        (name) =>
          this.siteAuditColumns
            .findBy('name', this.blockColumnName(name))
            .filter(Boolean) // remove undefined values
      );
      this.selectedSiteAuditSortColumnName = columnName;
      this.selectedSiteAuditSortDirection = direction;

      this.loadSiteAuditChecks.perform();
    }
  }

  columnSelected(columnName) {
    return !!this.selectedSiteAuditColumns.findBy('name', columnName);
  }

  resetSortIfDeselected() {
    if (!this.columnSelected(this.selectedSiteAuditSortColumnName)) {
      this.selectedSiteAuditSortColumnName = null;
      this.selectedSiteAuditSortDirection = null;
      this.args.blockType.sort = null;
      this.args.blockType.sort_direction = null;
    }
  }

  // Maps block name "none" => null
  // So that we don't send "none" values to the back-end
  blockColumnName(name) {
    return name === null ? 'none' : name;
  }

  selectSiteAuditChecks() {
    const includeChecks = this.siteAuditIncludeChecks;
    const checkIds = includeChecks ? this.siteAuditChecks.mapBy('id') : [];

    this.selectedSiteAuditCheckIds = checkIds;
    this.siteAuditIncludeChecks = includeChecks;
    this.args.blockType.site_audit_check_ids = checkIds;
    this.args.blockType.include_site_audit_checks = includeChecks;

    this.args.refreshPreview();
  }

  @action
  selectDynamicView(dynamicView) {
    this.selectedDynamicView = dynamicView;
    this.args.blockType.view_id = dynamicView.id;
    this.args.refreshPreview();
  }

  @action
  selectBacklinkView(backlinkView) {
    this.selectedBacklinkView = backlinkView;
    this.args.blockType.view_id = backlinkView.id;
    this.args.refreshPreview();
  }

  @action
  selectGraph(graph) {
    this.args.blockType.graph_id = graph.id;
    this.args.blockType.url_id = graph.url_id;
    this.selectedGraph = graph;
    this.args.refreshPreview();
  }

  @action
  selectSiteAuditView(siteAuditView) {
    this.selectedSiteAuditView = siteAuditView;
    this.args.blockType.view_id = siteAuditView.id;
    this.args.refreshPreview();
  }

  @action
  selectSiteAuditSnapshotId(id) {
    this.selectedSiteAuditSnapshotId = id;
    this.args.blockType.comparison_snapshot = id;
    this.args.refreshPreview();
  }

  @action
  toggleSiteAuditIncludeChecks() {
    this.siteAuditIncludeChecks = !this.siteAuditIncludeChecks;
    this.selectSiteAuditChecks();
  }

  @action
  selectSiteAuditCheckId(checkId) {
    const checkIds = this.selectedSiteAuditCheckIds;
    if (checkIds.includes(checkId)) {
      checkIds.removeObject(checkId);
    } else {
      checkIds.pushObject(checkId);
    }
    this.args.blockType.site_audit_check_ids = checkIds;
    if (checkIds.length === 0) {
      this.siteAuditIncludeChecks = false;
      this.args.blockType.include_site_audit_checks = false;
    }
    this.args.refreshPreview();
  }

  @action
  toggleSiteAuditIncludeAllPages() {
    const includeAllPages = !this.siteAuditIncludeAllPages;
    this.siteAuditIncludeAllPages = includeAllPages;
    this.args.blockType.include_all_pages = includeAllPages;
    this.args.refreshPreview();
  }

  @action
  selectSiteAuditColumn(index, column) {
    const splicedSelected = this.selectedSiteAuditColumns.slice();
    splicedSelected[index] = column;
    this.selectedSiteAuditColumns = splicedSelected;
    this.args.blockType.columns[index] = this.blockColumnName(column.name);
    // Must notify that this property has changed since we are modifying it directly
    this.resetSortIfDeselected();
    this.args.refreshPreview();
  }

  @action
  selectSiteAuditSort(columnName, direction) {
    if (columnName === 'none') return;
    if (!this.columnSelected(columnName)) return;

    this.selectedSiteAuditSortColumnName = columnName;
    this.selectedSiteAuditSortDirection = direction;
    this.args.blockType.sort = columnName;
    this.args.blockType.sort_direction = direction;
    this.args.refreshPreview();
  }

  @action
  handleKeywordComparisonChangeDate() {
    this.args.refreshPreview();
  }
}
