import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class NwToBottomButtonComponent extends Component {
  @tracked isAtBottom = false;
  @tracked scrollElement = document.documentElement;
  @tracked isScrollable = false;

  constructor() {
    super(...arguments);
    this.updateScrollElement();
    this.observeScrollElementChanges();
  }

  updateScrollElement() {
    let scrollElementSelector = this.args.scrollElementSelector;
    if (scrollElementSelector) {
      this.scrollElement =
        document.querySelector(scrollElementSelector()) ||
        document.documentElement;
    } else {
      this.scrollElement = document.documentElement;
    }
    this.updateIsScrollable();
  }

  updateIsScrollable() {
    this.isScrollable =
      this.scrollElement.scrollHeight > this.scrollElement.clientHeight;
  }

  observeScrollElementChanges() {
    let observer = new MutationObserver(() => {
      this.updateScrollElement();
    });
    observer.observe(document, { childList: true, subtree: true });
    this.willDestroy = () => {
      observer.disconnect();
      super.willDestroy(...arguments);
    };
  }

  get scrollListener() {
    return this.scrollElement === document.documentElement
      ? document
      : this.scrollElement;
  }

  @action
  onInsert() {
    this.scrollListener.addEventListener('scroll', this.handleScroll);
  }

  @action
  handleScroll() {
    let scrollEl = this.scrollElement;
    let documentHeight = scrollEl.scrollHeight;
    let currentScroll = scrollEl.scrollTop + scrollEl.clientHeight;
    let modifier = 100;
    this.isAtBottom = currentScroll + modifier > documentHeight;
  }

  @action async goToTopOrBottom() {
    let scrollEl = this.scrollElement;
    if (this.isAtBottom) {
      scrollEl.scrollTo(0, 0);
    } else {
      scrollEl.scrollTo({ top: scrollEl.scrollHeight });
      setTimeout(() => {
        scrollEl.scrollTo({ top: scrollEl.scrollHeight });
      }, 50);
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.scrollListener.removeEventListener('scroll', this.handleScroll);
  }
}
