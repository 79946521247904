import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class DashboardOverviewController extends Controller {
  @service('persistence/pinned-items') pinnedItemsPersistence;
  @service tour;
  @service session;
  @controller dashboard;
  @tracked selectedDateRange;
  @tracked askTourModal = false;
  @tracked shouldShowHelpButtonTour = false;
  featureName = 'dashboard-overview'; // Unique feature identifier

  get pinnedItems() {
    return this.model;
  }

  constructor() {
    super(...arguments);
    const { type, start, end } = this.pinnedItemsPersistence.parseDateRange();
    this.selectedDateRange = { type, start, end };

    this.initTour();
  }

  async initTour() {
    await this.tour.loadProgress();

    if (this.session.user?.isTrial) {
      this.askTourModal =
        !this.tour.hasCompletedTour(this.featureName) &&
        !this.tour.hasDismissedTour(this.featureName);
    } else {
      this.askTourModal = false;
      this.shouldShowHelpButtonTour =
        !this.tour.hasCompletedTour(this.featureName) &&
        !this.tour.hasDismissedTour(this.featureName);
    }
  }

  @action
  setDateRange({ start, end, type }) {
    this.selectedDateRange = { start, end, type };
    this.pinnedItemsPersistence.persistDateRange(type);
  }

  @action
  async start(special_step) {
    const steps = Array.isArray(special_step) ? special_step : TOUR_STEPS;
    console.log('steps', steps);
    const options = {
      animate: true,
      opacity: 0.75,
      allowClose: true,
      disableActiveInteraction: true,
      progressText: 'Step {{current}} of {{total}}',
    };

    await this.tour.startTour(this.featureName, steps, options);
  }

  @action
  handleTour() {
    this.start(ADDITIONAL_STEP);
  }
}

const TOUR_STEPS = [
  {
    element: '.pinned-items-header',
    popover: {
      title: 'Pinned items 📌',
      description:
        'Create pinned graphs for any project to track and visualize your most important metrics at a glance.',
      position: 'middle',
      showButtons: ['next'],
    },
  },
  {
    element: '#global-view-create-button',
    popover: {
      title: 'Creating Global Views 🌍',
      description:
        'Combine data from multiple URLs into one unified view for a complete performance overview.\n',
      position: 'bottom',
    },
  },
  {
    element: '#start-tour-button',
    popover: {
      title: 'Start tour',
      description:
        'You can always start the tour of this page by clicking this button.',
      position: 'bottom',
      nextBtnText: 'Thank you!',
    },
  },
];
const ADDITIONAL_STEP = [
  {
    element: '#start-tour-button',
    popover: {
      title: 'Start tour',
      description:
        'You can always start the tour of this page by clicking this button.',
      position: 'bottom',
      showButtons: ['next'],
      nextBtnText: 'Thank you!',
    },
  },
];
