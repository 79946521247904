import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class DashboardUrlKeywordsIndexController extends Controller {
  @service session;
  @service('persistence/keyword-list-sorting') keywordListSorting;
  @service tour;
  queryParams = ['page', 'direction', 'sort', 'filters'];

  @tracked url;
  @tracked dynamicView;

  @tracked page = 1;
  @tracked sort = 'created_at';
  @tracked direction = 'desc';
  @tracked filters = '';
  @tracked askTourModal = false;
  @tracked shouldShowHelpButtonTour = false;
  featureName = 'dashboard-keywords';

  async initTour() {
    await this.tour.loadProgress();

    if (this.session.user.isTrial) {
      this.askTourModal =
        !this.tour.hasCompletedTour(this.featureName) &&
        !this.tour.hasDismissedTour(this.featureName);
    } else {
      this.askTourModal = false;
      this.shouldShowHelpButtonTour =
        !this.tour.hasCompletedTour(this.featureName) &&
        !this.tour.hasDismissedTour(this.featureName);
    }
  }

  get parsedFilters() {
    let decoded = null;
    try {
      decoded = decodeURIComponent(atob(this.filters));
    } catch (error) {
      console.log(error);
      return decoded;
    }
    return decoded;
  }

  enteredRoute(url, dynamicView) {
    this.keywordListSorting.applySorting(this, { urlId: url.id });
    this.url = url;
    this.dynamicView = dynamicView;
    this.initTour();
  }

  @action
  updateParams({ page, sort, direction, filters }) {
    this.page = page ?? this.page;
    this.sort = sort ?? this.sort;
    this.direction = direction ?? this.direction;
    this.filters =
      btoa(decodeURI(encodeURIComponent(filters))) ??
      btoa(decodeURI(encodeURIComponent(this.filters)));
  }

  @action
  handleTour() {
    this.start(ADDITIONAL_STEP);
  }

  @action
  async start(specialStep) {
    const highlightJustTourButton = Array.isArray(specialStep);
    const steps = highlightJustTourButton
      ? [...specialStep]
      : [
          ...TOUR_STEPS_GENERAL_SIDEBAR,
          ...TOUR_STEPS_GENERAL_KEYWORD_TABLE,
          ...(this.url?.keyword_count > 0
            ? [...TOUR_STEPS_FOR_KEYWORD_ROW, ...ADDITIONAL_STEP]
            : []),
        ];

    const options = {
      animate: true,
      opacity: 0.75,
      allowClose: true,
      disableActiveInteraction: true,
      progressText: 'Step {{current}} of {{total}}',
    };

    await this.tour.startTour(this.featureName, steps, options);
  }
}

const TOUR_STEPS_GENERAL_SIDEBAR = [
  {
    element: '#url-dashboard-page',
    popover: {
      title: 'URL Dashboard 🌐',
      description:
        "This tab provides a live overview of your site's performance, including rankings and traffic metrics.",
      position: 'bottom',
      showButtons: ['next'],
    },
  },
  {
    element: '#url-keywords-page',
    popover: {
      title: 'Keyword rankings 📊',
      description:
        'Access all your keywords here and track their performance. Monitor trends, add, segment, and auto-discover keywords.',
      position: 'bottom',
    },
  },
  {
    element: '#url-chart-page',
    popover: {
      title: 'Charts page 📈',
      description:
        'Build custom graphs to visualize your data, track performance over time, and gain deeper insights into your metrics.',
      position: 'bottom',
    },
  },
  {
    element: '#url-site-audit-page',
    popover: {
      title: 'Site Audit 🛠',
      description:
        'Identify technical SEO issues, crawl errors, and optimization opportunities to improve site health and search performance.',
      position: 'bottom',
    },
  },
  {
    element: '#url-reports-page',
    popover: {
      title: 'Reports page 📄',
      description:
        'Create white-label reports with keyword rankings, traffic insights, graphs, and site audit data. Automate delivery to your team or clients.',
      position: 'bottom',
    },
  },
  {
    element: '#url-notes-page',
    popover: {
      title: 'Notes 📝',
      description:
        "Note changes, observations, campaigns, and events to track key details that impact your site's performance.",
      position: 'bottom',
    },
  },
  {
    element: '#url-settings-page',
    popover: {
      title: 'URL settings ⚙️',
      description:
        'Configure site audits, tracking options, add competitors, and connect Google Analytics for enhanced insights.',
      position: 'bottom',
    },
  },
  {
    element: '#deep-research-button',
    popover: {
      title: 'Deep Research 🕵️‍♂️',
      description:
        'Analyze subpages, uncover keyword opportunities, and get tailored recommendations to optimize your strategy.',
      position: 'bottom',
    },
  },
];

const TOUR_STEPS_GENERAL_KEYWORD_TABLE = [
  {
    element: '#advanced-keyword-filters',
    popover: {
      title: 'Advanced Keyword Filters 🛠',
      description:
        'Use advanced filters to segment your keywords based on specific criteria, such as rankings, location, tags, pages or any other metric.',
      position: 'top',
    },
  },
  {
    element: '#dynamic-column-dropdown',
    popover: {
      title: 'Dynamic columns ⚡️',
      description:
        'Use dynamic columns to enrich your keyword table with additional metrics and insights to help you track performance more effectively.',
      position: 'top',
    },
  },
  {
    element: '#keyword_ranking_step_1',
    popover: {
      title: 'Keyword dynamic views 📊',
      description:
        'Dynamic Views help you organize and segment your keywords efficiently!<br> Use this feature to group keywords based on criteria like tags, location, rank changes, or any other metric—making tracking performance at a glance easier.',
      position: 'bottom',
    },
  },
  {
    element: '#keyword_ranking_step_4',
    popover: {
      title: 'Add or Discover Keywords',
      description:
        'Here you can add new keywords to the URL or discover new ones.',
      position: 'bottom',
    },
  },
];

const TOUR_STEPS_FOR_KEYWORD_ROW = [
  {
    element: '.nw-table-header__col-last.nw-table-column__col-keyword-actions',
    popover: {
      title: 'SERP Preview 🔍',
      description:
        'Get a real-time view of how your keyword appears on search engine results!<br> Click here to open the SERP preview for each keyword and analyze historical rankings and your competitors directly from this dashboard.',
      position: 'bottom',
    },
  },
];

const ADDITIONAL_STEP = [
  {
    element: '#start-tour-button',
    popover: {
      title: 'Start tour',
      description:
        'You can always start the tour of this page by clicking this button.',
      position: 'bottom',
      showButtons: ['next'],
      nextBtnText: 'Thank you!',
    },
  },
];
