import Service, { inject as service } from '@ember/service';
import { allSettled, task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import isTesting from '../utils/is-testing';
import { htmlSafe } from '@ember/template';
import { action } from '@ember/object';
import { capitalizeString } from '../helpers/capitalize-string';

export default class SaveKeywordsService extends Service {
  @service fetch;
  @service discovery;
  @service session;
  @service store;
  @service notifications;
  @service router;
  @service filterGroupHandling;
  @tracked keywordTasks;
  @tracked upsertKeywords = false;
  @tracked createDynamicViews = false;
  MIN_TAG_KEYWORDS_FOR_VIEW = 3;

  get hasKeywordErrors() {
    return this.discovery.keywords.some((keyword) => keyword.errors.length > 0);
  }

  @action
  toggleUpsertKeywords() {
    this.upsertKeywords = !this.upsertKeywords;
  }

  @action
  toggleCreateDynamicViews() {
    this.createDynamicViews = !this.createDynamicViews;
  }

  @task({ maxConcurrency: 3, enqueue: true })
  *saveKeywordTask(keyword) {
    try {
      yield keyword.save({ adapterOptions: { upsert: this.upsertKeywords } });
    } catch (err) {
      throw new Error(err);
    }
  }

  @task({ drop: true })
  *updateKeywordTranslationTask(keyword, translation) {
    try {
      yield this.fetch.put(
        `/urls/${keyword.url.id}/keywords/${keyword.id}/update_translation`,
        {
          data: {
            translation: translation,
          },
        }
      );
      this.notifications.success('Translation saved successfully.', {
        autoClear: true,
      });
    } catch (err) {
      this.notifications.error(
        'Failed to save translation. Please try again.',
        {
          autoClear: true,
        }
      );
      throw new Error(err);
    }
  }

  @task({ drop: true })
  *saveKeywordsTask(callback, createDynamicViewsForTags = true) {
    const clearDuration = isTesting ? 0 : 6000;
    let success = false;
    if (this.session.user.keywordLimitReached) {
      this.notifications.error(
        htmlSafe(
          `You have reached your keyword limit! <a href="#">Click here</a> to upgrade your plan.`
        ),
        {
          onClick: () => {
            this.router.transitionTo('/plans');
            this.notifications.clearAll();
          },
        }
      );
      return;
    }

    this.discovery.keywords = [
      ...this.discovery.keywords?.filter((keyword) => keyword.query),
    ];
    const llmKeywords = this.discovery.keywords.filter(
      (keyword) => !!keyword.engine_type
    );
    if (llmKeywords.length) {
      if (!this.session.user.llm_keyword_limit) {
        this.notifications.error(
          htmlSafe(
            `LLM engines are currently in beta and will be available to all users soon.
    <a href="#">Contact support</a> if you would like to try them out.`
          ),
          {
            onClick: () => {
              window.location.href =
                'mailto:info@nightwatch.io?subject=Nightwatch LLM Beta';
              this.notifications.clearAll();
            },
          }
        );
        return;
      } else if (this.session.user.llmLimitReached) {
        this.notifications.error(
          htmlSafe(
            `You have reached your LLM keywords limit! <a href="#">Click here</a> to upgrade your plan.`
          ),
          {
            onClick: () => {
              this.router.transitionTo('/plans');
              this.notifications.clearAll();
            },
          }
        );
        return;
      }
    }

    if (this.discovery.keywords.length === 0) yield null;

    const tempKwMap = new Map();

    this.keywordTasks = this.discovery.keywords
      ?.map((keyword) => {
        const key = this.generateKeywordKey(keyword);

        if (tempKwMap.has(key)) {
          keyword.errors.add('query', 'has already been taken');
          return null;
        }

        tempKwMap.set(key, true);
        return this.saveKeywordTask.perform(keyword);
      })
      .filter(Boolean);

    yield allSettled(this.keywordTasks);

    if (!this.hasKeywordErrors) {
      if (typeof callback === 'function') callback();
      if (this.discovery.keywords.length > 0) {
        if (createDynamicViewsForTags) {
          let tagFrequency = this.getKeywordsTagFrequency(
            this.discovery.keywords
          );

          const commonTags = Array.from(tagFrequency.entries())
            .filter(([, count]) => count >= this.MIN_TAG_KEYWORDS_FOR_VIEW)
            .map(([tag]) => tag);
          const dynamicViewsNames = yield* this.getDynamicViewsNames();

          for (const tag of commonTags) {
            if (!tag) continue;
            const viewName = 'Tags: ' + capitalizeString(tag);
            if (
              this.isDynamicViewNameAvailable(dynamicViewsNames, tag, viewName)
            ) {
              yield this.saveTagDynamicView.perform(tag, viewName);
            }
          }
        }

        this.notifications.success(
          `Successfully saved ${this.discovery.keywords.length} keywords.`,
          { autoClear: true, clearDuration }
        );
        success = true;
      }
    } else {
      const successfullySavedKeywords = this.discovery.keywords.filter(
        (kw) => kw.id && !kw.isNew
      );
      if (successfullySavedKeywords.length) {
        this.discovery.keywords = this.discovery.keywords.filter(
          (kw) => !kw.isValid || kw.errorStrings.length
        );

        this.notifications.success(
          `Successfully saved ${successfullySavedKeywords.length} keywords.`,
          { autoClear: true, clearDuration }
        );
        this.notifications.warning(
          `But ${this.discovery.keywords.length} keywords were not saved, please check them for errors.`,
          { autoClear: true, clearDuration }
        );
        success = false;
      } else {
        this.notifications.error(
          `Failed to save keywords, please check them for errors.`,
          { autoClear: true }
        );
        success = false;
      }
    }
    this.keywordTasks = null;
    if (success) {
      this.session.user.reload();
      // Reloads the URL so the keyword count in the sidebar updates.
      this.discovery.keywords[0]?.url?.reload();
    }
    return { success: success };
  }

  isDynamicViewNameAvailable(dynamicViewsNames, tag, viewName) {
    return (
      !dynamicViewsNames ||
      (!dynamicViewsNames.includes(tag.toLowerCase()) &&
        !dynamicViewsNames.includes(viewName.toLowerCase()))
    );
  }

  getKeywordsTagFrequency(keywords) {
    let tagFrequency = new Map();
    keywords.forEach((keyword) => {
      keyword.tags.forEach((tag) => {
        tagFrequency.set(tag, (tagFrequency.get(tag) || 0) + 1);
      });
    });
    return tagFrequency;
  }

  *getDynamicViewsNames() {
    const dynamicViews = yield this.store.query('dynamic-view', {
      search_keyword_url_id: this.discovery.keywords[0].url.id,
      without_counts: true,
    });
    return dynamicViews.map((dv) => dv.name.toLowerCase());
  }

  @task
  *saveTagDynamicView(tag, viewName) {
    const dynamicView = this.store.createRecord('dynamic-view', {
      url: this.discovery.keywords[0].url,
      name: viewName,
    });

    yield dynamicView.save();

    const filterGroup = this.store.createRecord('filter-group', {
      dynamicView,
    });

    this.store.createRecord('filter', {
      filterGroup,
      field: 'keyword_tag',
      condition: 'equals',
      value: tag,
    });
    yield this.filterGroupHandling.saveViewFilters.perform(dynamicView);
  }

  @action
  generateKeywordKey(keyword) {
    return [
      keyword.query,
      keyword.mobile,
      keyword.location,
      keyword.google_gl,
      keyword.google_hl,
      keyword._engine, // Make unique key for each keyword for an engine
      keyword.engine_type,
      JSON.stringify(keyword.precise_location),
    ].join('|');
  }
}
