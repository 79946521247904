import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import isTesting from 'nightwatch-web/utils/is-testing';
import { tracked } from '@glimmer/tracking';
import { isLlmEngine } from '../../constants/keyword-data';

/**
 * This component wraps a keyword table for onboarding and adding keywords in url settings
 *
 * @param url {Url} current URL
 * @param keywordPreciseLocation {String} keywordPreciseLocation from keyword instance.
 * @param noAutoDiscover {Boolean} Prevents auto discovering keywords.
 * @param isSettingsMode {Boolean} Whether or not the component is in settings mode.
 * @param onKeywordsAddCallback {Function} Function to run after keywords are saved.
 * @param isInlineMode {Boolean} Whether or not the component is in inline mode.
 */

export default class KeywordDiscoveryProviderComponent extends Component {
  @service store;
  @service discovery;
  @service notifications;
  @service fetchTags;
  @service session;
  @service saveKeywords;
  @tracked showBatchAddKeywords = false;
  @tracked showGSCImport = false;
  @tracked selectedKeywords = [];
  @tracked keywordValidations;

  batchKeywords = '';

  columns = {
    default: {
      name: 'keyword-query',
      displayName: 'Keyword',
      sortProperty: 'query',
    },
    selected: [
      {
        name: 'keyword-location',
        displayName: 'Location',
        sortProperty: 'query',
      },
      {
        name: 'keyword-precise-location',
        displayName: 'Precise Location',
        tooltip:
          'Optional. Set your location before setting the precise location',
      },
      {
        name: 'keyword-search-engine',
        displayName: 'Search Engine',
      },
      {
        name: 'keyword-language',
        displayName: 'Language',
      },
      {
        name: 'keyword-device-type',
        displayName: 'Track On:',
      },
      {
        name: 'keyword-tags',
        displayName: 'Tags',
      },
      {
        name: 'keyword-translation',
        displayName: 'Translation',
        tooltip:
          'Optional. Set the translation of the keyword. It will not be used for tracking.',
      },
      {
        name: 'keyword-actions',
        displayName: '',
      },
    ],
    drop: () => null,
  };

  get keywordProperties() {
    return {
      url: this.args.url,
      google_gl: this.args.url?.country_code?.toLowerCase(),
      google_hl: this.args.url?.language_code?.toLowerCase(),
      _engine: 'google',
      tags: [],
      precise_location: this.args.keywordPreciseLocation,
      mobile: false,
    };
  }

  get keywords() {
    return this.discovery.keywords;
  }

  get keywordsToCopy() {
    return this.selectedKeywords
      .map((keyword) => {
        return keyword?.query?.trim();
      })
      ?.join('\n');
  }

  get duckDuckGoLanguages() {
    // DuckDuckGo has a special "worldwide" option https://duckduckgo.com/params
    return [{ id: 'wt', text: 'No preference' }].concat(this.languages);
  }

  get allRowsSelected() {
    return Boolean(
      this.selectedKeywords.length &&
        this.selectedKeywords.length === this.discovery.keywords.length
    );
  }

  get selectedKeywordsGoogleGl() {
    const gls = this.selectedKeywords.map((keyword) => keyword.google_gl);
    return gls.reduce(
      (a, b, index, arr) =>
        arr.filter((v) => v === a).length >= arr.filter((v) => v === b).length
          ? a
          : b,
      null
    );
  }

  get selectedKeywordsHaveSameLocation() {
    return this.selectedKeywords.mapBy('google_gl').uniq().length === 1;
  }

  get isYouTubeUrl() {
    return this.args.url?.isYoutube;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    if (this.args.isInlineMode) {
      this.removeAllKeywords();
    } else {
      this.removeEmptyKeywords();
    }
  }

  @action
  onInsert() {
    this.getValidationData.perform();

    if (
      this.isYouTubeUrl ||
      (this.args.isSettingsMode &&
        this.discovery.keywords.length === 0 &&
        !this.args.isInlineMode)
    ) {
      this.addEmptyKeywords(3);
    }

    if (this.args.isInlineMode) {
      this.addEmptyKeywords(1);
      return;
    }

    if (
      this.isYouTubeUrl ||
      !this.args.url ||
      this.discovery.keywords.length ||
      this.args.noAutoDiscover
    )
      return;
    this.discoverTask.perform();
  }

  @task({ drop: true })
  *discoverTask() {
    const url = this.args.url;
    const countryCode = this.args.url.country_code?.toLowerCase();
    const preciseLocation = this.args.keywordPreciseLocation;

    try {
      let keywords = (yield this.discovery.fetchKeywordSuggestions.perform(
        url,
        countryCode
      )).data;

      if (keywords.length < 1) {
        this.warnNoKeywords();
      }

      if (keywords.length > 100) {
        keywords = keywords.slice(0, 100);
      }

      keywords.forEach((kw) => {
        const keyword = this.store.createRecord('keyword', {
          query: kw.keyword,
        });
        for (let k in this.keywordProperties)
          keyword[k] = this.keywordProperties[k];
        keyword.preciseLocation = preciseLocation; // Necessary for setter side effects
        this.discovery.keywords.pushObject(keyword);
      });
      if (
        !isTesting &&
        !this.args.isSettingsMode &&
        this.discovery.keywords.length === 0
      ) {
        this.addEmptyKeywords(1);
      }

      this.applyOpenAITags();
    } catch (e) {
      this.errorSearchingKeywords();
    }
  }

  @task({ drop: true })
  *getValidationData() {
    if (isTesting) {
      this.keywordValidations = {
        invalid_characters: [',', '،', '!', '@'],
        max_word_count: 10,
        max_character_count: 80,
      };
      return;
    }
    const request = yield fetch(
      'https://api.nightwatch.io/api/v1/keyword_validations'
    );
    this.keywordValidations = yield request.json();
  }

  @action
  writeBatchKeywords(keywords) {
    this.batchKeywords = keywords;
  }

  @action
  addKeyword() {
    const keyword = this.store.createRecord('keyword');
    // Copy initially discovered keyword properties into new keyword.
    this.copyKeywordProperties(keyword, this.keywordProperties);
    this.discovery.keywords = [...this.discovery.keywords, keyword];
  }

  @action
  duplicateKeyword(keywordToCopy) {
    const newKeyword = this.store.createRecord('keyword', {
      query: keywordToCopy.query,
    });
    for (let k in this.keywordProperties) {
      newKeyword[k] = Array.isArray(keywordToCopy[k])
        ? keywordToCopy[k].slice()
        : keywordToCopy[k];
    }
    if (keywordToCopy.precise_location) {
      newKeyword.preciseLocation = keywordToCopy.precise_location;
    }
    if (keywordToCopy.engine_type && isLlmEngine(keywordToCopy.engine_type)) {
      newKeyword._engine = keywordToCopy.engine_type;
    }
    this.discovery.keywords = [...this.discovery.keywords, newKeyword];
  }

  @action
  removeKeyword(keyword) {
    this.discovery.keywords = this.keywords.without(keyword);
    this.selectedKeywords = this.selectedKeywords.without(keyword);
  }

  @action
  batchAddKeywords() {
    // Adds keywords that are unique and ignores empty strings.
    const batchKeywords = this.batchKeywords
      ?.split('\n')
      .filter((keyword) => keyword.length)
      .map((keyword) => keyword.trim())
      .uniq();

    // Remove keywords without queries when batch adding keywords.
    this.discovery.keywords
      .filter((keyword) => !keyword.query)
      .forEach((keyword) => this.removeKeyword(keyword));

    if (batchKeywords.length > this.session.user.keywords_remaining) {
      this.notifications.error(
        `You tried to add ${batchKeywords.length} keywords, but you only have ${this.session.user.keywords_remaining} remaining.`,
        { autoClear: true }
      );
      return;
    }

    const createdKeywords = batchKeywords.map((query) => {
      const newKeyword = this.store.createRecord('keyword', { query });
      this.copyKeywordProperties(newKeyword, this.keywordProperties);
      return newKeyword;
    });

    this.removeEmptyKeywords();

    this.discovery.keywords = [...this.discovery.keywords, ...createdKeywords];
    this.showBatchAddKeywords = false;
  }

  removeEmptyKeywords() {
    // Removes empty keywords without queries.
    this.discovery.keywords = this.discovery.keywords.filter(
      (keyword) => keyword.query
    );
  }

  removeAllKeywords() {
    this.discovery.keywords = [];
  }

  copyKeywordProperties(keyword, keywordProperties) {
    for (let key in keywordProperties) {
      keyword[key] = Array.isArray(keywordProperties[key])
        ? keywordProperties[key].slice() // Needed to copy array instead of referencing existing one internally.
        : keywordProperties[key];
    }
    if (keywordProperties.precise_location) {
      keyword.preciseLocation = keywordProperties.precise_location;
    }
  }

  @action
  toggleKeywordSelected(keyword) {
    if (this.selectedKeywords.includes(keyword)) {
      this.selectedKeywords.removeObject(keyword);
    } else {
      this.selectedKeywords.pushObject(keyword);
    }
  }

  @action
  onSelectAllToggleChange(checked) {
    if (checked) {
      this.selectedKeywords = [...this.discovery.keywords];
    } else {
      this.selectedKeywords = [];
    }
  }

  @action
  batchChangeKeywordProperties(key, value) {
    if (key === 'google_gl') {
      this.selectedKeywords.forEach((keyword) => {
        keyword.precise_location = null;
      });
    }
    this.selectedKeywords.forEach((keyword) => {
      keyword[key] = value;
    });
  }

  @action
  batchDeleteKeywords() {
    if (!confirm('Are you sure you want to delete the selected keywords?'))
      return;
    this.selectedKeywords.forEach((keyword) => {
      this.removeKeyword(keyword);
    });
  }

  @action
  batchDuplicateKeywords() {
    this.selectedKeywords.forEach((keyword) => {
      this.duplicateKeyword(keyword);
    });
  }

  @action
  addEmptyKeywords(amount) {
    [...Array(amount)].forEach(() => this.addKeyword());
  }

  warnNoKeywords() {
    if (isTesting) return;
    this.notifications.warning(
      "Couldn't find any keywords - the website is probably new.",
      { autoClear: true, clearDuration: 4000 }
    );
  }

  errorSearchingKeywords() {
    this.notifications.error('Fetching Keywords', {
      type: 'warning',
      message: 'Auto discovery problems. Please contact support.',
    });
  }
}
