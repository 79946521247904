import { tracked } from '@glimmer/tracking';
import Service, { inject as service } from '@ember/service';
import config from '../config/environment';
import { task } from 'ember-concurrency';

export default class DiscoveryService extends Service {
  @service session;
  @service fetch;
  @tracked keywords = [];
  @tracked competitors = [];

  @task({ drop: true })
  *fetchKeywordSuggestions(url, _countryCode) {
    try {
      const response = yield fetch(
        `${config.apiServiceGatewayURL}/keywords/generate-keywords`,
        {
          method: 'POST',
          headers: {
            Authorization: `${this.session.token}`,
          },
          body: JSON.stringify({ url: url.url }),
        }
      );
      if (response.ok) {
        return yield response.json();
      } else {
        throw new Error('Error fetching keywords');
      }
    } catch (e) {
      this.notifications.error(e.message || 'Error fetching keywords', {
        autoClear: true,
      });
      throw new Error('Error fetching keywords');
    }
  }

  fetchCompetitorSuggestions(url) {
    return this.fetch.request(`/urls/${url.id}/competitor_suggestions`);
  }

  fetchKeywordSuggestionsCount(url) {
    return this.fetch.request(`/urls/${url.id}/keyword_suggestion_count`);
  }

  clearEmptyKeywords() {
    this.keywords = this.keywords.filter((keyword) => keyword.query);
  }
}
