import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class TourService extends Service {
  @service store;
  @service session;

  @tracked driverInstance = null;
  @tracked showTour = false;
  @tracked completedSteps = new Set();
  @tracked dismissedSteps = new Set(); // Track dismissed tours

  get currentUser() {
    return this.session?.user;
  }

  async loadDriver() {
    if (!this.driverInstance) {
      const { driver } = await import('driver.js');
      await import('driver.js/dist/driver.css');
      this.driverInstance = driver();
    }
    return this.driverInstance;
  }

  async startTour(featureName, steps = [], options = {}) {
    const driver = await this.loadDriver();
    driver.destroy(); // Ensure fresh instance

    driver.setConfig({
      ...options,
      onDestroyed: () => this.saveProgress(featureName, 'completed'),
    });

    driver.setSteps(steps);
    driver.drive();
  }

  async highlightElement(elementSelector) {
    const driver = await this.loadDriver();
    driver.highlight({
      element: elementSelector,
      popover: {
        title: 'Highlighted Element',
        description: 'This element is now highlighted.',
      },
    });
  }

  reset() {
    if (this.driverInstance) {
      this.driverInstance.destroy();
      this.driverInstance = null;
    }
  }

  /** Load stored tour progress from the user model */
  async loadProgress() {
    if (!this.currentUser) return;

    try {
      const tourData = JSON.parse(this.currentUser.tour_steps || '{}');
      this.completedSteps = new Set(tourData.completed || []);
      this.dismissedSteps = new Set(tourData.dismissed || []);
    } catch (e) {
      console.error('Failed to parse tour steps:', e);
      this.completedSteps = new Set();
      this.dismissedSteps = new Set();
    }
  }

  /** Save completed or dismissed tour feature */
  async saveProgress(featureName, status) {
    if (!this.currentUser) return;

    const tourData = {
      completed: [...this.completedSteps],
      dismissed: [...this.dismissedSteps],
    };

    if (status === 'completed') {
      this.completedSteps.add(featureName);
      tourData.completed = [...this.completedSteps];
    } else if (status === 'dismissed') {
      this.dismissedSteps.add(featureName);
      tourData.dismissed = [...this.dismissedSteps];
    }

    this.currentUser.tour_steps = JSON.stringify(tourData);

    try {
      await this.currentUser.save();
    } catch (e) {
      console.error('Failed to save tour progress:', e);
    }
  }

  /** Check if the user has already completed a tour */
  hasCompletedTour(featureName) {
    return this.completedSteps.has(featureName);
  }

  /** Check if the user dismissed a tour */
  hasDismissedTour(featureName) {
    return this.dismissedSteps.has(featureName);
  }

  /** Reset tour progress for a specific feature */
  async resetTourProgress(featureName) {
    this.completedSteps.delete(featureName);
    this.dismissedSteps.delete(featureName);

    if (this.currentUser) {
      this.currentUser.tour_steps = JSON.stringify({
        completed: [...this.completedSteps],
        dismissed: [...this.dismissedSteps],
      });

      await this.currentUser.save();
    }
  }
}
