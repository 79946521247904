import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class TourInviteModalComponent extends Component {
  @service tour;
  @tracked askTourModal = true;

  constructor(owner, args) {
    super(owner, args);
    this.askTourModal =
      !this.tour.hasCompletedTour(this.args.featureName) &&
      !this.tour.hasDismissedTour(this.args.featureName);
  }

  @action
  async rejectTour() {
    this.askTourModal = false;
    await this.tour.saveProgress(this.args.featureName, 'dismissed'); // Persist dismissal
  }

  @action
  async acceptTour() {
    this.askTourModal = false;
    await this.args.start();
  }

  @action
  async resetTour() {
    await this.tour.resetTourProgress(this.args.featureName);
    this.askTourModal = true;
  }
}
