import isTesting from 'nightwatch-web/utils/is-testing';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { debounce, schedule } from '@ember/runloop';
import ENV from 'nightwatch-web/config/environment';
import generateUserMetrics from 'nightwatch-web/utils/generate-user-metrics';

export default class ApplicationRoute extends Route {
  @service router;
  @service faye;
  @service fetch;
  @service intercom;
  @service metrics;
  @service updateVersionChecker;
  @service userData;
  @service session;
  @service siteData;
  nProgress = null;

  beforeModel(transition) {
    if (transition.targetName.includes('maintenance')) {
      return;
    }

    if (transition.to.queryParams.t) {
      this.session.ingestQueryParamToken(transition.to.queryParams.t);
    }

    if (!this.session.isAuthenticated) {
      return this.siteData.load();
    }

    // user promise must be returned here, so we ensure user is loaded before dashboard activates
    return this.loadUser(transition);
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    if (isTesting) return;
    this.updateVersionChecker.get('checkNewVersion').perform();
  }

  async loadUser(transition) {
    let user;
    try {
      user = await this.session.loadUser.perform();
    } catch (error) {
      this.session.invalidate();
      this.handleUnauthenticated(transition);
      return;
    }
    this.redirectUser(user, transition);
    schedule('afterRender', () => this.setUpServices(user));
  }

  async handleUnauthenticated(transition) {
    await this.siteData.load();

    if (transition.targetName !== 'login') {
      this.router.transitionTo('login');
    }
  }

  redirectUser(user, transition) {
    if (!user.get('confirmed')) {
      if (transition.targetName !== 'confirm-account') {
        this.router.transitionTo('confirm-account');
      }
    } else {
      if (
        ['dashboard', 'dashboard.index', 'login'].includes(
          transition.targetName
        )
      ) {
        this.router.transitionTo('dashboard.overview');
      }
    }
    if (
      ['dashboard', 'dashboard.index', 'login'].includes(transition.targetName)
    ) {
      this.router.transitionTo('dashboard.overview');
    }
  }

  setUpServices(user) {
    const userMetrics = generateUserMetrics(user, this.siteData);
    const { userId, email } = userMetrics;

    this.setupGA(userId);
    this.setupGTAG(userId);
    this.intercom.afterLogin(userMetrics);

    this.faye.handlePushUpdates({
      onKeywordPositionUpdate: () =>
        debounce(this, 'refreshUrlOverviewWithFallback', 850),
    });
    this.userData.save();

    this.session.trackLogin.perform();
  }

  setupGA(userId) {
    if (this.session.isAdminViewingUser) return;
    this.metrics.identify('GoogleAnalytics', { distinctId: userId });
  }

  setupGTAG(userId) {
    if (this.session.isAdminViewingUser || !window.gtag) return;
    const { config } = ENV.metricsAdapters.findBy('name', 'GoogleAnalytics');
    window.gtag('config', config.id, {
      custom_map: { dimension2: 'user_id' },
    });
    window.gtag('event', 'user_id_dimension', { user_id: userId });
  }

  refreshUrlOverview() {
    const keywordsIndexController = this.controller.get(
      'dashboardUrlKeywordsIndex'
    );
    if (keywordsIndexController && keywordsIndexController.fetchKeywordStats) {
      keywordsIndexController.fetchKeywordStats();
      keywordsIndexController.showKeywordNotifications();
    }
  }

  refreshUrlOverviewWithFallback() {
    if (isTesting) return;
    this.refreshUrlOverview();

    // Refresh again to give some time for the Query API to adjust
    // FIXME: The Query API should notify us when the data is ready
    debounce(this, 'refreshUrlOverview', 5000);
  }

  @action
  loading(transition) {
    this.importNProgress();
    this.nProgress?.start();
    transition.promise.finally(() => {
      this.nProgress?.done();
    });
    return true;
  }

  @action
  error(error) {
    console.error(error); // Leave this here – make sure application/routing errors show in the console
    const status = error?.errors?.firstObject?.status;

    if (status === '503') {
      this.router.transitionTo('maintenance');
      return;
    } else if (status === '401') {
      this.session.invalidate();
      this.router.transitionTo('dashboard.overview');
      return;
    }

    this.router.transitionTo('error');
  }

  importNProgress() {
    if (this.nProgress) return;
    import('nprogress').then((module) => (this.nProgress = module));
  }
}
