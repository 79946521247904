import Component from '@glimmer/component';
import { task, rawTimeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { emailRegex } from '../utils/regexes';

/**
  @class LoginFormComponent

  Login form for authentication
 */
export default class LoginFormComponent extends Component {
  @service session;
  @service router;

  @tracked email;
  @tracked password;
  @tracked showPassword = false;
  @tracked emailError = '';
  @tracked passwordError = '';

  @task({ restartable: true })
  *login() {
    if (!this.isEmailValid()) {
      this.emailError = 'Please enter a valid email address';
      return;
    }

    if (!this.isPasswordValid()) {
      this.passwordError = 'Please enter a password';
      return;
    }
    const { email, password } = this;

    try {
      yield this.session.login.perform({ email, password });
      /*
        If this operation succeeds, a browser redirect will be initiated
        Keep the task running for 2 seconds – the "Login" button will continue to show "Logging in..."
      */
      yield rawTimeout(2000);
    } catch (error) {
      let errorMessage =
        typeof error === 'string'
          ? error
          : 'Login failed. Please check your credentials and try again.';
      this.emailError = errorMessage;
      this.passwordError = errorMessage;
    }
  }

  @action
  isEmailValid() {
    return this.email && emailRegex.test(this.email);
  }

  @action
  isPasswordValid() {
    return this.password && this.password.length > 0;
  }

  @action
  resetPasswordError() {
    this.passwordError = '';
  }

  @action
  resetEmailError() {
    this.emailError = '';
  }
}
