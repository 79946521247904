import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
export default class NwOnboardingFlowKeywordRowComponent extends Component {
  @tracked cachedPreciseLocation;
  @tracked enablePreciseLocation;

  get isInvalidKeyword() {
    return (
      this.args.keywordValidations?.invalid_characters?.some((substring) =>
        this.args.keyword.query?.includes(substring)
      ) || this.args.keyword.query?.split(/[ ,.]/).length > 10
    );
  }

  constructor(owner, args) {
    super(owner, args);

    this.args.keyword.mobile =
      this.args.keyword.mobile ?? this.args.url.device ?? false;
    this.args.keyword._engine =
      this.args.keyword._engine || this.args.url.engine || 'google';
  }

  @action
  onInsert() {
    if (this.args.keyword.preciseLocation) {
      this.enablePreciseLocation = true;
    }
  }

  @action
  onUpdate() {
    // When the keyword.precise_location gets updated, disable the location selector based on precise_location presence.
    this.enablePreciseLocation = !!this.args.keyword.preciseLocation;
  }

  @action
  togglePreciseLocation() {
    if (!this.enablePreciseLocation) this.args.keyword.preciseLocation = null;
  }

  @action
  updateEngine(newEngine) {
    this.args.keyword._engine = newEngine;
  }
}
