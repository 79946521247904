export const allSearchEngines = [
  {
    id: 'google',
    text: 'Google',
    icon: '/assets/icons/google-icon.svg',
    selectIcon: '/assets/icons/blue/google.svg',
  },
  {
    id: 'youtube',
    text: 'YouTube',
    icon: '/assets/icons/youtube-icon.png',
    selectIcon: '/assets/icons/blue/youtube.svg',
  },
  {
    id: 'places',
    text: 'Google Places',
    icon: '/assets/icons/places-icon.png',
    selectIcon: '/assets/icons/blue/googlemaps.svg',
  },
  {
    id: 'bing',
    text: 'Bing',
    icon: '/assets/icons/bing-icon.png',
    selectIcon: '/assets/icons/blue/bing.svg',
  },
  {
    id: 'yahoo',
    text: 'Yahoo',
    icon: '/assets/icons/yahoo-icon.png',
    selectIcon: '/assets/icons/blue/yahoo.svg',
  },
  {
    id: 'duckduckgo',
    text: 'DuckDuckGo',
    icon: '/assets/icons/duckduckgo-icon.png',
    selectIcon: '/assets/icons/blue/duckduckgo.svg',
  },
];

export const llmSearchEngines = [
  {
    id: 'gpt-4o-mini',
    text: 'GPT-4o Mini',
    llm: true,
    icon: '/assets/icons/gpt-4o-mini.svg',
    selectIcon: '/assets/icons/blue/gpt-4o-mini.svg',
  },
  {
    id: 'claude-3.5-haiku',
    text: 'Claude 3.5 Haiku',
    llm: true,
    icon: '/assets/icons/claude-3.5-haiku.svg',
    selectIcon: '/assets/icons/blue/claude-3.5-haiku.svg',
  },
];

export function getAllSearchEnginesWithLlms() {
  return [
    ...allSearchEngines,
    { id: 'llm', text: 'LLM', icon: '/assets/icons/blue/llm.svg' },
  ];
}

export function getAllSearchEngines() {
  return [...allSearchEngines, createLlmEnginesGroup(), ...llmSearchEngines];
}

function createLlmEnginesGroup() {
  return { groupName: 'LLM Engines', options: [] };
}

export function isLlmEngine(engine) {
  return llmSearchEngines.some((llm) => llm.id === engine);
}

export function getLlmengines() {
  return llmSearchEngines;
}

export const bingCountries = [
  { title: 'Argentina', location: 'ar', language: 'es' },
  { title: 'Australia', location: 'au', language: 'en' },
  { title: 'Austria', location: 'at', language: 'de' },
  { title: 'Belgium - Dutch', location: 'be', language: 'nl' },
  { title: 'Belgium - French', location: 'be', language: 'fr' },
  { title: 'Brazil', location: 'br', language: 'pt' },
  { title: 'Canada - English', location: 'ca', language: 'en' },
  { title: 'Canada - French', location: 'ca', language: 'fr' },
  { title: 'Chile', location: 'cl', language: 'es' },
  { title: 'Denmark', location: 'dk', language: 'da' },
  { title: 'Finland', location: 'fi', language: 'fi' },
  { title: 'France', location: 'fr', language: 'fr' },
  { title: 'Germany', location: 'de', language: 'de' },
  { title: 'Hong Kong S.A.R.', location: 'hk', language: 'zh' },
  { title: 'India', location: 'in', language: 'en' },
  { title: 'Indonesia', location: 'id', language: 'en' },
  { title: 'Italy', location: 'it', language: 'it' },
  { title: 'Japan', location: 'jp', language: 'ja' },
  { title: 'Korea', location: 'kr', language: 'ko' },
  { title: 'Malaysia', location: 'my', language: 'en' },
  { title: 'Mexico', location: 'mx', language: 'es' },
  { title: 'Netherlands', location: 'nl', language: 'nl' },
  { title: 'New Zealand', location: 'nz', language: 'en' },
  { title: 'Norway', location: 'no', language: 'nb' },
  { title: "People's Republic of China", location: 'cn', language: 'zh' },
  { title: 'Poland', location: 'pl', language: 'pl' },
  { title: 'Portugal', location: 'pt', language: 'pt' },
  { title: 'Republic of the Philippines', location: 'ph', language: 'en' },
  { title: 'Russia', location: 'ru', language: 'ru' },
  { title: 'Saudi Arabia', location: 'sa', language: 'ar' },
  { title: 'South Africa', location: 'za', language: 'en' },
  { title: 'Spain', location: 'es', language: 'es' },
  { title: 'Sweden', location: 'se', language: 'sv' },
  { title: 'Switzerland - French', location: 'ch', language: 'fr' },
  { title: 'Switzerland - German', location: 'ch', language: 'de' },
  { title: 'Taiwan', location: 'tw', language: 'zh' },
  { title: 'Turkey', location: 'tr', language: 'tr' },
  { title: 'United Kingdom', location: 'uk', language: 'en' },
  { title: 'United States - English', location: 'us', language: 'en' },
  { title: 'United States - Spanish', location: 'us', language: 'es' },
];

export const yahooCountries = [
  { title: 'United States', location: 'us', language: 'en' },
  { title: 'Europe', location: 'gb', language: 'en' },
];

export default {
  allSearchEngines,
  bingCountries,
  yahooCountries,
};
