import { alias, map } from '@ember/object/computed';
import { later, debounce } from '@ember/runloop';
import Component from '@ember/component';
import EmberObject, { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';

export default Component.extend({
  session: service(),
  subscriptionData: service(),
  couponValidator: service(),
  paymentAmountCalculator: service(),
  paymentIsProcessing: false,
  countries: alias('subscriptionData.countries'),
  user: alias('session.user'),
  countryCode: computed('user.invoice_country', 'countries.[]', function () {
    const countryCode = this.findCountryCode(
      this.countries,
      this.get('user.invoice_country') || ''
    );
    return countryCode || 'US';
  }),

  init() {
    this._super();
    this.set('couponState', EmberObject.create());
    this.set('vatRate', 0.0);
    this.updateVatRate();
  },

  country: computed('countryCode', 'countries.[]', function () {
    if (!this.countries || !this.countryCode) {
      return;
    }
    return this.findCountryName(this.countries, this.countryCode);
  }),

  formId: computed('mode', function () {
    return this.mode === 'update' ? 'update_payment' : 'create_subscription';
  }),

  isSubscribeMode: computed('mode', function () {
    return this.mode === 'subscribe';
  }),

  countriesForDropdown: map('countries', function (country) {
    return {
      id: country[1],
      name: country[0],
    };
  }),

  couponStateDescription: computed(
    'couponState.{valid,duration,percent,error}',
    'plan.periodName',
    function () {
      if (this.get('couponState.valid')) {
        const period = this.get('plan.periodName').replace('ly', '');
        const percent = this.get('couponState.percent');
        const duration = this.get('couponState.duration');
        if (duration && duration > 0) {
          if (duration === 1) {
            return `You'll receive a ${percent}% discount for the first ${period}!`;
          } else {
            return `You'll receive a ${percent}% discount for the first ${duration} ${pluralize(
              period
            )}!`;
          }
        } else {
          return `You'll receive a ${percent}% discount!`;
        }
      } else if (this.get('couponState.valid') === false) {
        return this.get('couponState.error') || 'Invalid or expired coupon';
      }
    }
  ),

  amount: computed(
    'plan.price',
    'couponState.percent',
    'vatRate',
    'mode',
    function () {
      // When updating a payment method, we pass 1.00 for 3D Secure verification.
      // Response from Braintree support:
      // "It is important to note that it is not yet possible to do perform 0.00 amount verifications,
      // so we are currently throwing an error when 0 is passed as the amount in the verifyCard call to avoid failure later in the process.
      //  If you wish to implement an equivalent to card verifications with 3DS right now, you would need to pass 1.00 as the amount in the verifyCard call."
      if (this.mode == 'update') return 1.0;

      const planPrice = Number.parseFloat(this.get('plan.price'));
      if (Number.isNaN(planPrice)) return 0;
      let couponPercent = Number.parseFloat(this.get('couponState.percent'));
      if (Number.isNaN(couponPercent)) couponPercent = 0;

      const amountVat = (this.vatRate / 100) * planPrice;
      const amountDiscounted = (couponPercent / 100) * planPrice;

      return planPrice - amountDiscounted + amountVat;
    }
  ),

  paymentAmounts: computed(
    'plan.price',
    'couponState.percent',
    'vatRate',
    function () {
      return {
        vat_rate: this.vatRate,
        coupon_percent: this.couponState.percent,
        amount_original: this.plan.price,
      };
    }
  ),

  // This country code finding trickery is because we save invoice_country as
  // full country names instead of IDs, and we need to operate with IDs here
  findCountryCode(countriesArray, country) {
    const result = this._findCountry(countriesArray, country, 0);
    if (result) return result[1];
  },

  findCountryName(countriesArray, country) {
    const result = this._findCountry(countriesArray, country, 1);
    if (result) return result[0];
  },

  _findCountry(countriesArray, country, index) {
    const arr = countriesArray.filter(
      (countryWithCode) =>
        countryWithCode[index].toLowerCase() === country.toLowerCase()
    );
    return arr[0];
  },

  validateCoupon(coupon) {
    if (coupon) {
      const planId = this.get('plan.id');
      return this.couponValidator
        .validateCoupon(coupon, planId)
        .then((result) => {
          const attributes = Object.assign(
            { valid: null, error: null, percent: 0 },
            result
          );
          this.couponState.setProperties(attributes);
        });
    } else {
      return this.couponState.setProperties({
        valid: null,
        error: null,
        percent: 0,
      });
    }
  },

  async updateVatRate() {
    const country = this.country;
    const vatNumber = this.get('user.invoice_vat_number');
    try {
      const data = await this.paymentAmountCalculator.fetchVatRate(
        country,
        vatNumber
      );
      this.set('vatRate', data.vat_rate);
    } catch (e) {
      console.log(e);
    }
  },

  updateVatNumber: action(function (value) {
    this.set('user.invoice_vat_number', value);
    this.updateVatRate();
  }),
  onCountryChange: action(function (value) {
    this.set('countryCode', value);
    this.updateVatRate();
  }),
  toggleShowCouponInput: action(function () {
    this.toggleProperty('showCouponInput');
    if (this.showCouponInput) {
      later(
        this,
        () => {
          this.element.querySelector('.coupon-area .rt-input').focus();
        },
        100
      );
    } else {
      this.set('coupon', null);
      this.couponState.setProperties({ valid: null, error: null });
    }
  }),

  onCouponChange: action(function (coupon) {
    this.set('coupon', coupon);
    debounce(this, 'validateCoupon', coupon, 250);
  }),

  setUserProperties: action(function () {
    this.set('user.invoice_country', this.country);
    if (!this.get('user.is_company')) {
      this.set('user.invoice_vat_number', null);
    }
    this.set('user.password', null);
  }),

  resetErrors: action(function () {
    this.set('userError', null);
    this.set('paymentError', null);
  }),

  setPaymentError: action(function (paymentError) {
    this.set('paymentError', paymentError);
  }),

  setUserError: action(function (error) {
    this.set('userError', error);
  }),

  setProcessing: action(function (paymentIsProcessing) {
    this.set('paymentIsProcessing', paymentIsProcessing);
  }),
});
