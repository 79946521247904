import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

export default class DashboardUrlSettingsSiteAuditController extends Controller {
  @service router;
  @service session;
  @service store;
  @service fetch;

  queryParams = ['startCrawling'];
  @tracked startCrawling = false;

  @action
  saveAndStartCrawling(url) {
    this.set('startingCrawlOperation', true);
    url.save().then(
      (url) => {
        const crawlingSession = this.store.createRecord(
          'site-audit/crawling-session',
          {
            searchKeywordUrlId: url.id,
          }
        );

        crawlingSession.save().then(
          (crawlingSession) => {
            url.reload(); // To refresh next_site_audit_crawl_at

            url.loadLastCrawlingSession(); // To show "All Pages" in sidebar
            // To not show the "start crawling" in the button immediately
            later(() => {
              this.set('startingCrawlOperation', false);
            }, 2000);

            let path = 'dashboard.url.site-audit';

            this.startCrawling = false;

            this.router.transitionTo(path, {
              queryParams: {
                crawling_session_id: crawlingSession.id,
              },
            });
          },
          () => {
            this.set('startingCrawlOperation', false);
            this.router.transitionTo('dashboard.url.site-audit');
          }
        );
      },
      () => {
        this.set('startingCrawlOperation', false);
        this.notifications.error('There was an error while saving settings.', {
          autoClear: true,
          clearDuration: 3500,
        });
      }
    );
  }

  @action
  saveUrl(url) {
    if (url.siteAuditInterval) {
      url.nextSiteAuditCrawlAt = new Date(
        Date.now() + url.siteAuditInterval * 1000
      );
    } else {
      url.nextSiteAuditCrawlAt = null;
    }

    url.save().then(
      () => {
        this.notifications.success('Settings successfully saved.', {
          autoClear: true,
          clearDuration: 3000,
        });
      },
      () => {
        this.notifications.error('There was an error while saving settings.', {
          autoClear: true,
          clearDuration: 3000,
        });
      }
    );
  }

  @action
  cancel(url) {
    if (!confirm('Are you sure to cancel and stop the crawling session?')) {
      return;
    }

    this.fetch
      .delete(`site_audit/crawling_sessions/${url.id}`)
      .then(() => url.reload())
      .then(() => (this.startCrawling = true))
      .then(() => {
        this.notifications.success('Crawling session successfully canceled', {
          autoClear: true,
          clearDuration: 3000,
        });
      })
      .catch(() => {
        this.notifications.error(
          'There was an error while canceling crawling session',
          {
            autoClear: true,
            clearDuration: 3000,
          }
        );
      });
  }
}
