import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import isDev from '../../utils/is-dev';

export default class CurrentPlanComponent extends Component {
  @service session;
  @service intercom;
  @service fetch;
  @service notifications;
  @tracked showCancelFlow = false;

  get user() {
    return this.session?.user;
  }

  get stripeCustomer() {
    return this.user?.stripeCustomer;
  }

  get showPlansLink() {
    if (this.stripeCustomer && this.user?.isCancelled) return false;
    // Show to *all* cancelled and expired customers
    if (this.user?.isCancelled || this.user?.isExpired) return true;
    // Only show to non-trial Stripe customers
    if (this.user?.isTrial && !this.stripeCustomer) return true;
    return false;
  }

  @action
  toggleShowCancellationFlow() {
    if (isDev) {
      this.showCancelFlow = !this.showCancelFlow;
      return;
    }
    fetch('https://hooks.zapier.com/hooks/catch/4790508/b0gtw9l/', {
      method: 'POST',
      body: JSON.stringify({
        email: this.session.user.email,
      }),
    });
    this.showCancelFlow = !this.showCancelFlow;
  }

  @task({ drop: true })
  *resumePlanTask() {
    try {
      yield this.fetch.request('/stripe/resume_subscription', {
        method: 'PATCH',
      });
      this.session.user.reload();

      this.notifications.success('Your plan has been successfully resumed.', {
        autoClear: true,
        clearDuration: 5000,
      });
    } catch (e) {
      this.notifications.error(
        'Error resuming your plan. Please try again or contact our support.',
        { autoClear: true, clearDuration: 5000 }
      );
    }
  }
}
