import Component from '@glimmer/component';
import localStorage from 'ember-local-storage-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class NwDeepResearchHistoryItemSelectorComponent extends Component {
  @service deepResearch;
  @localStorage('researchHistory') researchHistory;

  get groupedHistoryItems() {
    return this.groupHistoryItems();
  }

  groupHistoryItems() {
    if (!this.researchHistory) {
      return [];
    }
    const grouped = this.researchHistory.reduce((acc, item, index) => {
      const itemWithIndex = { ...item, originalIndex: index };
      if (!acc[item.url]) {
        acc[item.url] = [];
      }
      acc[item.url].push(itemWithIndex);

      return acc;
    }, {});

    for (let url in grouped) {
      // if url does not end with a slash, add it

      grouped[url] = grouped[url].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    }

    return grouped;
  }

  get groupedHistoryArray() {
    return Object.entries(this.groupedHistoryItems).map(([url, items]) => {
      return { url, items };
    });
  }

  @action
  async prepareDownloadItem(item, format) {
    const { classification, pages, recommendations, search_keywords } =
      this.researchHistory[item];

    // Define a title and subtitle for the PDF reports
    const title = 'Research Report - ' + this.args.currentUrl;
    const subtitle = 'Research was done on ' + this.args.currentReportDate;

    // Define a base filename
    const fileNameBase =
      this.args.currentUrl +
      '-complete-research-' +
      this.args.currentReportDate;

    // Use the updated exportData function for multi-section PDF export
    await this.deepResearch.exportData(
      { classification, pages, recommendations, search_keywords }, // Multi-section data
      format, // Export format (pdf, json, etc.)
      null, // Title for the PDF
      null,
      title,
      subtitle, // Subtitle for the PDF
      fileNameBase // Base filename
    );
  }
}
