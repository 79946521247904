import Component from '@glimmer/component';
import {
  getAllSearchEngines,
  getAllSearchEnginesWithLlms,
} from 'nightwatch-web/constants/keyword-data';
import { allEngines } from '../../utils/filters/keyword-page-filter-state';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class NwOnboardingFlowEngineSelectComponent extends Component {
  @tracked options = [];

  constructor() {
    super(...arguments);
    this.loadOptions();
  }

  async loadOptions() {
    this.options = this.args.allowAllEngines
      ? [allEngines].concat(getAllSearchEnginesWithLlms())
      : await getAllSearchEngines();
  }

  get selected() {
    return this.options.find((engine) => engine.id === this.args.selectedId);
  }

  @action
  onChange(engine) {
    this.args.onSelected(engine.id);
  }
}
