import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { startUrlRegex } from '../utils/regexes';
import localStorage from 'ember-local-storage-decorator';
import { formatISO } from 'date-fns';
import posthog from 'posthog-js';

// The progress step for each stage of the research process, it should be: 100 / number of stages
const PROGRESS_STEP = 50;

/**
 * The ResearchController is responsible for handling the deep research functionality.
 * It is used to manage the state of the research process, including the URL, keywords, and recommendations.
 * It also provides methods for submitting the research, handling the progress bar, and saving the research history.
 */
export default class ResearchController extends Controller {
  queryParams = ['url'];
  // services
  @service deepResearch;
  @service theme;
  @service router;
  @service store; // Inject the store service
  @service discovery;
  @service notifications;

  // research state
  @tracked isSubmitted = false;
  @tracked isResearchLoading = false;
  @tracked isResearchStarted = false;
  @tracked currentTab = null;
  @tracked progress = 0;
  @tracked progressStatus = '';
  @tracked showWeakText = false;
  @tracked urlError = false;
  @tracked exportToUrlModalOpen = false;

  //research history
  @tracked selectedItemIndex = null;
  @tracked _researchCopy = null;
  @localStorage('researchHistory') researchHistory;

  // Advanced research options
  @tracked google_gl = 'us';
  @tracked google_hl = 'en';
  @tracked focusKeywords = '';
  @tracked geoTarget = '';
  // inputs
  @tracked url = '';
  @tracked urlInput = '';
  @tracked currentUsages = 100;
  @tracked totalBalance = 100;
  @tracked classificationEnabled = true;

  constructor() {
    super(...arguments);
    this.getBalance.perform();
  }

  @action
  toggleClassification() {
    this.classificationEnabled = !this.classificationEnabled;
  }

  @task({ drop: false })
  *selectUrlForKeywordExport(url) {
    this.exportToUrlModalOpen = false;
    let classifications;
    const selectedKeywords = this.deepResearch.keywords.filter(
      (kw) => kw.selected
    );

    if (this.classificationEnabled) {
      try {
        classifications = yield this.classifyKeywords.perform(
          selectedKeywords,
          this.urlInput
        );

        this.getBalance.perform();

        if (classifications) {
          classifications = new Map(
            classifications.map((obj) => [obj.keyword, obj])
          );
        }
      } catch (e) {
        classifications = null;
      }
    }

    const kwModels = selectedKeywords.map((kw) => {
      const newKw = this.store.createRecord('keyword', {
        query: kw.keyword,
      });

      if (classifications) {
        newKw.tags = this.getTagsArray(kw.keyword, classifications);
      } else {
        newKw.tags = [];
      }
      newKw.url = url;
      if (this.preciseLocation) {
        newKw.precise_location = this.preciseLocation;
      }

      if (this.google_hl) {
        newKw.google_hl = this.google_hl;
      }

      if (this.google_gl) {
        newKw.google_gl = this.google_gl;
      }
      return newKw;
    });
    this.discovery.keywords = kwModels;

    const analytics = {
      research_url: this.urlInput,
      export_to_url: url.url,
      classification_enabled: this.classificationEnabled,
    };
    this.gatherAnalytics('deep_research_keywords_exported', analytics);
    this.router.transitionTo('dashboard.url.keywords.add', url);
  }

  getTagsArray(keyword, classifications) {
    if (!classifications || !classifications.has(keyword)) {
      return [];
    }

    const classfication = classifications.get(keyword);
    const tags = [
      ...classfication['branded_tags'],
      ...classfication['category_tags'],
      ...classfication['intent_tags'],
    ];

    if (classfication['is_branded']) {
      tags.push('branded');
      tags.push(classfication['is_our_brand'] ? 'our brand' : 'not our brand');
    }

    return tags;
  }

  @action
  validateUrl(touched = false) {
    if (!this.urlInput && !touched) {
      return;
    }
    const isValid = this.urlInput.match(startUrlRegex) !== null;
    this.urlError = !isValid || !this.urlInput;
    return this.urlError;
  }

  @action
  deleteHistoryItem(index) {
    this.researchHistory = this.researchHistory.toSpliced(index, 1);
  }

  @action
  selectHistoryItem(index) {
    this.currentTab = null;
    this.urlInput = this.researchHistory[index].url;
    this.deepResearch.pages = this.researchHistory[index].pages;
    this.deepResearch.recommendations =
      this.researchHistory[index].recommendations;
    this.deepResearch.keywords = [...this.researchHistory[index].keywords];
    this.deepResearch.keywords = this.deepResearch.keywords.sort((a, b) => {
      return Number(b?.avgMonthlySearches) - Number(a?.avgMonthlySearches);
    });

    this.google_gl = this.researchHistory[index].google_gl ?? 'us';
    this.google_hl = this.researchHistory[index].google_hl ?? 'en';
    this.focusKeywords = this.researchHistory[index].focusKeywords ?? '';
    this.geoTarget = this.researchHistory[index].geoTarget;

    this._researchCopy = {
      ...this.researchHistory[index],
    };
    this.isResearchLoading = false;
    this.isResearchStarted = true;
    this.isSubmitted = true;
    this.selectedItemIndex = index;

    setTimeout(() => {
      this.currentTab = 1;
    }, 0);
  }

  /**
   * Adds a beforeunload event listener to warn the user if they attempt to close or refresh the page.
   */
  @action
  addBeforeUnloadListener() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  /**
   * Removes the beforeunload event listener.
   */
  @action
  removeBeforeUnloadListener() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  /**
   * Handles the beforeunload event, showing a confirmation dialog to the user.
   */
  handleBeforeUnload(event) {
    event.preventDefault();
    event.returnValue =
      'Are you sure you want to leave? Your research is still running.';
  }

  @task({ drop: false })
  *submitResearch() {
    if ((!this.urlInput || this.validateUrl(true)) && !this.focusKeywords) {
      return;
    }
    if (this.isResearchStarted) {
      return;
    }
    this.addBeforeUnloadListener();

    this.dropProgress();

    this.isResearchLoading = true;
    this.isResearchStarted = true;

    try {
      // Fetch subpages
      setTimeout(() => {
        this.showWeakText = true;
      }, 7000);
      let preparedUrl = null;
      if (this.urlInput) {
        preparedUrl = this.prepareUrl(this.urlInput);
        this.deepResearch.pages = yield this.extractPages.perform(
          this.urlInput
        );
      }

        this.deepResearch.keywords =
          yield this.generateAdvancedKeywords.perform(
            this.urlInput,
            this.focusKeywords,
            this.geoTarget
          );
      this.currentUsages -= 2;
      this.getBalance.perform();

      this.deepResearch.keywords = this.deepResearch.keywords.map((kwObj) => {
        return {
          ...kwObj,
          selected: false,
          id:
            kwObj.id ||
            Math.random().toString(36).slice(2, 9) + new Date().getTime(),
        };
      });

      this.isResearchLoading = false;
      this.isSubmitted = true;
      this.currentTab = 1;

      this.addResearchToHistory(
        preparedUrl,
        this.deepResearch.pages,
        this.deepResearch.keywords,
        this.google_gl,
        this.google_hl,
        this.focusKeywords,
        this.geoTarget
      );
      this.removeBeforeUnloadListener();

      const analytics = {
        url: this.urlInput,
      };
      if (this.focusKeywords) {
        analytics.focusKeywords = this.focusKeywords;
        analytics.geoTarget = this.geoTarget;
        analytics.google_gl = this.google_gl;
        analytics.google_hl = this.google_hl;
      }
      this.gatherAnalytics('deep_research_submitted', analytics);
    } catch (e) {
      console.error(e);
      this.dropProgress();
    }
  }

  addResearchToHistory(
    url,
    pages,
    keywords,
    google_gl,
    google_hl,
    focusKeywords,
    geoTarget
  ) {
    const created_at = formatISO(new Date());
    if (!this.researchHistory) {
      this.researchHistory = [];
    }

    const newResearch = {
      url,
      pages,
      keywords,
      created_at,
    };

    newResearch.google_gl = google_gl;
    newResearch.google_hl = google_hl;
    newResearch.focusKeywords = focusKeywords;
    newResearch.geoTarget = geoTarget;

    this.researchHistory = [
      ...this.researchHistory,
      {
        ...newResearch,
      },
    ];
    this.selectedItemIndex = this.researchHistory.length - 1;

    this._researchCopy = {
      url,
      pages,
      // recommendations,
      keywords,
      // classification,
      created_at,
      google_gl,
      google_hl,
      focusKeywords,
      geoTarget,
    };
  }

  dropProgress() {
    this.progress = 0;
    this.progressStatus = '';
    this.deepResearch.pages = null;
    this.deepResearch.recommendations = null;
    this.deepResearch.keywords = null;
    this.isResearchLoading = false;
    this.isSubmitted = false;
    this.isResearchLoading = false;
    this.isResearchStarted = false;
    this.removeBeforeUnloadListener();
  }

  @task({ drop: false })
  *extractPages(url) {
    try {
      this.progressStatus = 'Collecting subpages';
      const rawPages = (yield this.deepResearch.getUrls.perform(url)).data;
      if (!rawPages || rawPages.length === 0) {
        this.notifications.error('We were not able to find any subpages.', {
          autoClear: true,
        });
        throw new Error('We were not able to find any subpages.');
      }
      this.increaseProgress();

      return rawPages.map((url, index) => {
        return { url, index: index + 1 };
      });
    } catch (e) {
      this.increaseProgress();
      return [];
    }
  }

  @task({ drop: false })
  *populateKeywords(url) {
    // Populate keywords
    this.progressStatus = 'Populating keywords';
    const keyword_ideas = (yield this.deepResearch.extractKeywords.perform(url))
      .data;

    if (!keyword_ideas || keyword_ideas.length === 0) {
      this.notifications.error('We were not able to find any keywords.', {
        autoClear: true,
      });
      throw new Error('We were not able to find any keywords.');
    }

    this.increaseProgress();

    return keyword_ideas.sort((a, b) => {
      return Number(b.avgMonthlySearches) - Number(a.avgMonthlySearches);
    });
  }

  @task({ drop: false })
  *generateAdvancedKeywords(url, keywords, geoTargets) {
    // Generate advanced keywords
    this.progressStatus = 'Generating advanced keywords';
    const keyword_ideas =
      (yield this.deepResearch.extractAdvancedKeywords.perform(
        url,
        keywords ? keywords.split(',').map((kw) => kw.trim() && kw): [],
        geoTargets ? ['geoTargetConstants/' + geoTargets.id] : []
      )).data;

    if (!keyword_ideas || keyword_ideas.length === 0) {
      this.notifications.error('We were not able to find any keywords.', {
        autoClear: true,
      });
      throw new Error('We were not able to find any keywords.');
    }

    this.increaseProgress();

    return keyword_ideas.sort((a, b) => {
      return Number(b.avgMonthlySearches) - Number(a.avgMonthlySearches);
    });
  }

  @task({ drop: false })
  *classifyKeywords(keyword_ideas, url) {
    // Classify keywords
    this.progressStatus = 'Classifying keywords';
    const classified_keywords =
      yield this.deepResearch.classifyKeywords.perform(keyword_ideas, url);

    if (!classified_keywords || classified_keywords.length === 0) {
      this.notifications.error('We were not able to classify any keywords.', {
        autoClear: true,
      });
      throw new Error('We were not able to classify any keywords.');
    }
    // this.increaseProgress();
    return classified_keywords;
  }

  @task({ drop: false })
  *generateRecommendations() {
    let recommendations;
    let selectedKeywords;
    if (this.isAdvanceMode) {
      recommendations = yield this.getAdvancedRecommendations.perform(
        this.urlInput,
        this.focusKeywords,
        this.google_gl,
        this.google_hl
      );
    } else {
      selectedKeywords = this.deepResearch.keywords.filter((k) => k.selected);
      if (selectedKeywords.length === 0) {
        selectedKeywords = this.deepResearch.keywords;
      }

      // Softlock to reduce api errors
      // TODO: Remove when someone will take care of the API problems
      if (selectedKeywords.length > 50) {
        selectedKeywords = selectedKeywords.slice(0, 50);
      }

      recommendations = yield this.getRecommendations.perform(
        this.urlInput,
        selectedKeywords
      );
      this.getBalance.perform();
    }

    if (!recommendations) {
      recommendations = [];
    }

    recommendations.map((rec) => {
      rec.status = 'pending';
      return rec;
    });
    if (
      !this.deepResearch.recommendations ||
      this.deepResearch.recommendations.length === 0
    ) {
      // If recommendations don't exist or are empty, assign new recommendations
      this.deepResearch.recommendations = recommendations;
    } else {
      // If recommendations exist, concatenate the new ones
      this.deepResearch.recommendations =
        this.deepResearch.recommendations.concat(recommendations);
    }

    const currentSavedResearch = this.researchHistory[this.selectedItemIndex];
    // delete currentSaved researcg from history
    this.researchHistory = this.researchHistory.toSpliced(
      this.selectedItemIndex,
      1
    );
    // Create a new research object with the updated recommendations
    const updatedResearch = {
      ...currentSavedResearch,
      recommendations: this.deepResearch.recommendations, // Updated recommendations
    };

    // Insert the updated research back into the same position
    this.researchHistory = [
      ...this.researchHistory.slice(0, this.selectedItemIndex),
      updatedResearch,
      ...this.researchHistory.slice(this.selectedItemIndex),
    ];

    // Optionally, update the current state (if necessary)
    this._researchCopy = { ...updatedResearch };

    const analytics = {
      url: this.urlInput,
      isAdvancedMode: this.isAdvanceMode,
    };

    if (this.isAdvanceMode) {
      analytics.focusKeywords = this.focusKeywords;
      analytics.geoTarget = this.geoTarget;
      analytics.google_gl = this.google_gl;
      analytics.google_hl = this.google_hl;
    }
    this.gatherAnalytics('deep_research_recommendations_generated', analytics);
  }

  get notEnoughKeywordsSelectedForRecommendations() {
    return !(this.deepResearch.keywords.filter((k) => k.selected).length >= 1);
  }

  @task({ drop: false })
  *getRecommendations(url, keywords) {
    const res = (yield this.deepResearch.getRecommendations.perform(
      this.urlInput,
      keywords.reduce((acc, kwObj) => acc + ', ' + kwObj.keyword, '')
    )).data;
    if (!res || res.length === 0) {
      this.notifications.error(
        'We were not able to generate any recommendations based on your data.',
        {
          autoClear: true,
        }
      );
      return [];
    }
    return res;
  }

  @task({ drop: false })
  *getAdvancedRecommendations(url, keyword_ideas, google_gl, google_hl) {
    const res = (yield this.deepResearch.getAdvancedRecommendations.perform(
      this.urlInput,
      keyword_ideas,
      google_gl,
      google_hl
    )).data;
    this.getBalance.perform();

    if (!res || res.length === 0) {
      this.notifications.error(
        'We were not able to generate any recommendations based on your data.',
        {
          autoClear: true,
        }
      );
      return [];
    }
    return res;
  }

  prepareUrl(url) {
    // Ensure the URL starts with 'http'
    if (!url.startsWith('http')) {
      url = `https://${url}`;
    }

    // Ensure 'https://' is followed by 'www.'
    if (!url.includes('www.')) {
      const protocolEnd = url.indexOf('://') + 3; // position after 'https://'
      url = url.slice(0, protocolEnd) + 'www.' + url.slice(protocolEnd);
    }

    // Ensure the URL ends with a '/'
    if (!url.endsWith('/')) {
      url += '/';
    }

    return url;
  }

  @action
  increaseProgress() {
    this.progress += PROGRESS_STEP;
  }

  @action
  preventFormSubmit(event) {
    event.preventDefault();
  }

  @action
  requestRetry() {
    this.urlInput = '';
    this.focusKeywords = '';
    this.google_hl = 'en';
    this.google_gl = 'us';
    this.preciseLocation = '';

    this.isSubmitted = false;
    this.isResearchStarted = false;
    this.currentTab = null;
  }

  @action
  onNewLocationSearch(location) {
    this.geoTarget = location;
  }

  @action
  approveRecommendation(recommendation) {
    const recommendations = [...this.deepResearch.recommendations];
    const idx = recommendations.findIndex((rec) => rec === recommendation);

    recommendations.removeObject(recommendation);
    recommendations.splice(idx, 0, { ...recommendation, status: 'approved' });

    this.deepResearch.recommendations = recommendations;
    this.saveResearch();
  }

  @action
  rejectRecommendation(recommendation) {
    const recommendations = [...this.deepResearch.recommendations];
    const idx = recommendations.findIndex((rec) => rec === recommendation);

    recommendations.removeObject(recommendation);
    recommendations.splice(idx, 0, { ...recommendation, status: 'rejected' });

    this.deepResearch.recommendations = recommendations;
    this.saveResearch();
  }

  @action
  saveResearch() {
    const currentSavedResearch = this.researchHistory[this.selectedItemIndex];
    // delete currentSaved researcg from history
    this.researchHistory = this.researchHistory.toSpliced(
      this.selectedItemIndex,
      1
    );
    // Create a new research object with the updated recommendations
    const updatedResearch = {
      ...currentSavedResearch,
      recommendations: this.deepResearch.recommendations,
    };

    // Insert the updated research back into the same position
    this.researchHistory = [
      ...this.researchHistory.slice(0, this.selectedItemIndex),
      updatedResearch,
      ...this.researchHistory.slice(this.selectedItemIndex),
    ];

    // Optionally, update the current state (if necessary)
    this._researchCopy = { ...updatedResearch };
  }

  get currentReportDate() {
    let date = new Date(this._researchCopy?.created_at);

    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    let year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  @task({ drop: false })
  *getBalance() {
    try {
      const res = yield this.deepResearch.getBalance.perform();
      this.currentUsages = res.total_usage;
      this.totalBalance = res.monthly_limit;
    } catch (e) {
      console.error(e);
    }
  }

  gatherAnalytics(event, properties) {
    posthog.capture(event, properties);
  }
}
