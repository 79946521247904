import { inject as service } from '@ember/service';
import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import { friendlyUrl } from 'nightwatch-web/helpers/friendly-url';
import { tracked } from '@glimmer/tracking';
import { task, all } from 'ember-concurrency';
import underscorifyKeys from '../utils/underscorify-keys';

export default class Url extends Model {
  @service store;
  @service discovery;
  @service fetch;

  @attr('string') url;
  @attr('string') url_type; //legacy
  @attr('string') custom_name;
  @attr('string') places_keyword;
  @attr() places_info;
  @attr('string') places_match;
  @attr('string') places_image_title;
  @attr('number', { readOnly: true }) keyword_count;
  @attr('number', { readOnly: true }) report_count;
  @attr('string') google_analytics_profile_id;
  @attr('number') google_analytics_property_id;
  @attr('string') search_console_url;
  @attr('string') youtube_channel;
  @attr() url_info;
  @attr('date') created_at;
  @attr('number', { readOnly: true }) google_indexed;
  @attr('number', { readOnly: true }) google_indexed_change;
  @attr('string', { serialize: false }) source; // onboarding, ...
  @attr('string') country_code;
  @attr('string') language_code;
  @attr('string', { readOnly: true }) google_analytics_error;
  @attr('date', { readOnly: true }) keyword_suggestions_updated_at;
  @attr('date', { readOnly: true }) google_analytics_updated_at;
  @attr('boolean', { readOnly: true }) backlinksEnabled;
  @attr('boolean') backlinksFetchingEnabled;
  @attr('number', { readOnly: true }) backlinksCount;
  @attr() backlinksStatus;
  @attr('boolean') includeLocalPackInMainPosition;
  @attr('boolean') includePlacesImageInMainPosition;
  @attr('boolean') includeFeaturedSnippetInMainPosition;
  @attr('boolean') includeKnowledgePanelInMainPosition;
  @attr('boolean') matchNestedUrls;
  @attr('boolean') matchSubdomains;
  @attr() seo_metrics;
  @attr() seo_metrics_changes;
  @attr('string') crawling_session_path_ignore_pattern;
  @attr('boolean') crawling_session_url_params_enabled;
  @attr('boolean') crawling_session_follow_nofollow_links;
  @attr('number', { defaultValue: 3 }) crawling_session_concurrency_level;
  @attr('number') siteAuditInterval;
  @attr('date') nextSiteAuditCrawlAt;
  @attr() backlinksSettings;
  @attr('boolean') include_ai_overview_snippet_in_main_position;
  @attr('string') onboardingStep;

  @belongsTo('user', { async: true }) user;
  @belongsTo('url-group', { async: false }) urlGroup;
  @hasMany('competitor', { async: false }) competitors;
  @hasMany('note', { async: false }) notes;
  @hasMany('dynamic-view', { async: false }) dynamicViews;
  @hasMany('backlink-view', { async: false }) backlinkViews;
  @hasMany('site-audit/view', { async: false }) siteAuditViews;
  @hasMany('graph', { async: false }) graphs;
  @tracked reports;

  @tracked keywordSuggestionCount;
  @tracked lastCrawlingSession;
  @tracked stats;
  @tracked engine;
  @tracked device;

  get backlinksLastRefresh() {
    const lastCheck = this.get('backlinksStatus.last_ahrefs_fill_try');
    if (isPresent(lastCheck)) {
      return new Date(lastCheck);
    } else {
      return null;
    }
  }

  get domain() {
    // when RankTrackr users who added a "Google Place" switch to Nightwatch,
    // url can be a string like "Johnson's Bakery"
    try {
      const urlObj = new URL(this.url);
      return urlObj.hostname;
    } catch (e) {
      return this.url;
    }
  }

  get friendlyUrl() {
    const url = this.url || '';
    return friendlyUrl(url);
  }

  get name() {
    return (
      this.custom_name ||
      this.youtube_channel ||
      this.url_info?.title ||
      this.friendlyUrl
    );
  }

  get isYoutube() {
    return this.url.toLowerCase().indexOf('youtube.com') !== -1;
  }

  get faviconUrl() {
    try {
      const hostName = new URL(this.url).host;
      return `https://unavatar.io/${hostName}`;
    } catch {
      return '/assets/icons/default-favicon.png';
    }
  }

  init() {
    super.init();
    this.reports = [];
  }

  @task({ drop: true })
  *loadRelationships() {
    const tasks = [
      this.loadCompetitors.perform(),
      this.loadDynamicViews.perform(),
      this.loadBacklinkViews.perform(),
      this.loadLastCrawlingSession(), // Site Audit stuff
      this.loadSiteAuditViews.perform(), // For sidebar "All Pages" count
      this.loadNotes.perform(),
      this.loadGraphs.perform(),
    ];

    yield all(tasks);
  }

  @task({ enqueue: true })
  *loadKeywords() {
    if (this.loadKeywords.lastSuccessful)
      return this.loadKeywords.lastSuccessful.value;

    const keywords = yield this.store.query(
      'keyword',
      underscorifyKeys({ urlId: this.id })
    );
    return keywords;
  }

  async loadKeywordSuggestionCount() {
    const data = await this.discovery.fetchKeywordSuggestionsCount(this);
    this.keywordSuggestionCount = data.keyword_suggestion_count;
  }

  @task({ enqueue: true })
  *loadNotes({ reload } = { reload: false }) {
    if (!reload && this.loadNotes.lastSuccessful) return;

    yield this.store.query(
      'note',
      underscorifyKeys({ includeGlobal: true, urlId: this.id })
    );
  }

  async loadStats(dateFrom, dateTo) {
    const stats = await this.fetch.request('/url_stats', {
      data: {
        url_id: this.id,
        date_from: dateFrom,
        date_to: dateTo,
      },
    });
    this.stats = stats;
  }

  async loadLastCrawlingSession() {
    const urlId = this.id;
    const crawlingSessions = await this.store.query(
      'site-audit/crawling_session',
      {
        search_keyword_url_id: urlId,
        last: true,
      }
    );
    this.lastCrawlingSession = crawlingSessions.firstObject;
  }

  @task({ restartable: true })
  *loadGraphs() {
    yield this.store.query('graph', { url_id: this.id });
  }

  @task({ enqueue: true })
  *loadCompetitors({ reload } = { reload: false }) {
    if (this.loadCompetitors.lastSuccessful && !reload) return;

    yield this.store.query('competitor', { url_id: this.id });
  }

  @task({ restartable: true })
  *loadDynamicViews() {
    yield this.store.query('dynamic-view', {
      search_keyword_url_id: this.id,
      without_counts: true,
    });
  }

  @task({ restartable: true })
  *loadBacklinkViews() {
    yield this.store.query('backlink-view', {
      url_id: this.id,
    });
    this.backlinkViews.forEach((bv) => bv.loadCount());
  }

  @task({ restartable: true })
  *loadSiteAuditViews() {
    const siteAuditViews = yield this.store.query('site-audit/view', {
      search_keyword_url_id: this.id,
    });
    siteAuditViews.forEach((view) => view.loadCount());
  }
}
