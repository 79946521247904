import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class ResearchRoute extends Route {
  @service router;
  @service store;
  @service session;

  beforeModel(transition) {
    this.session.requireAuthentication();
  }

  model(params) {
    return params;
  }

  deactivate() {
    this.controller.url = null;
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('urlInput', model.url);
  }
}
