import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { THEMES } from '../services/theme';

export default class RedeemInvitationRoute extends Route {
  controllerName = 'signup-form';

  @service session;
  @service router;
  @service theme;

  beforeModel(transition) {
    this.session.prohibitAuthentication();

    if (!transition.to.queryParams.token) {
      this.router.transitionTo('login');
    }
    this.theme.current = THEMES.BRIGHT;
  }

  model(params, transition) {
    return {
      signupToken: transition.to.queryParams.token,
      isInvitation: true,
    };
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('isWhiteLabel', this.siteData.isWhiteLabel);
  }

  renderTemplate() {
    this.render('signup-form');
  }
}
