import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import Route from '@ember/routing/route';

export default class UpdatePaymentRoute extends Route {
  @service session;
  @service externalScriptLoader;
  @service fetch;

  async beforeModel() {
    this.session.requireAuthentication();
    try {
      await this.externalScriptLoader.loadBillingScripts();
    } catch (error) {
      this.notifications.error(
        'There was an error while setting up the payment form. Please reload the page, or contact support if issue persists.'
      );
      console.error(error);
    }
  }

  async model() {
    return hash({
      paymentData: this.fetch.request('/user/payment_token'),
    });
  }

  @action
  willTransition() {
    this.session.user.rollbackAttributes();
  }
}
